import React, { useEffect } from "react";
import PageHero from "../components/PageHero";
import Newsletter from "../components/Newsletter";
import Becoming from "../components/Becoming";

const Membership = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <PageHero name="Membership Application Portal" />
      <Becoming />
      <Newsletter />
    </>
  );
};

export default Membership;
