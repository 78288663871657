import React, { useState } from "react";
import aboutImg from "../images/business-chart.jpg";
import axios from "axios";
// import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  // const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost", {
        // .post("https://api.thegoodhouseng.com/login", {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setMessage(res.data.success);
          // window.location.href = "https://admin.thegoodhouseng.com";
          window.location.href = "http://localhost";
          // navigate("http://localhost:3001/", { replace: false });
        } else {
          setMessage(res.data.error);
        }
        // console.log("ok", res);
        // setMessage("Login successful");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  return (
    <div>
      <section
        className="vh-100 bg-image"
        style={{
          backgroundImage: `url(${aboutImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="mask d-flex align-items-center h-100 gradient-custom-3">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                <div className="card" style={{ borderRadius: 15 }}>
                  <div className="card-body p-5">
                    <h2 className="text-uppercase text-center mb-5">Login</h2>
                    <p>{message}</p>
                    <form onSubmit={onSubmit}>
                      <div className="form-outline mb-4">
                        <input
                          type="email"
                          id="form3Example3cg"
                          className="form-control form-control-lg"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label className="form-label" htmlFor="form3Example3cg">
                          Your Email
                        </label>
                      </div>

                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="form3Example4cg"
                          className="form-control form-control-lg"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="form-label" htmlFor="form3Example4cg">
                          Password
                        </label>
                      </div>
                      {/* <div className="form-check d-flex justify-content-center mb-5">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          value=""
                          id="form2Example3cg"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="form2Example3g"
                        >
                          Remember Me...{" "}
                        </label>
                      </div> */}

                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                    <p className="text-center py-3">
                      Not registered?
                      <a href="/register"> Sign Up </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
