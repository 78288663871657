import React, { useEffect } from "react";
import Signup from "../components/Signup";

const Application = (props) => {
  useEffect(() => {
    props.funcNav(false);
  }, [props]);
  return (
    <>
      <Signup />
    </>
  );
};

export default Application;
