import React from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { Link } from "react-router-dom";
import { STATE_URL } from "../../requestMethods";

const CardDiv = styled.section`
  display: flex;
  gap: 1.5rem;
  min-width: 380px;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  @media screen and (max-width: 780px) {
    width: 95%;
    flex-direction: column;
  }
`;

// const Card = styled.div`
//   color: #fff;
//   background-image: url(${(props) => props.img});
//   background-size: cover;
//   padding: 10rem 0 0;
//   max-width: 50ch;
//   border-radius: 0.5rem;
//   overflow: hidden;
//   position: relative;
// `;

const NewsContainer = styled.div`
  display: grid;
  max-width: 50ch;

  img {
    height: 350px;
  }
`;

const Title = styled.h2`
  color: red;
  font-size: 1.2rem;
  margin: 1rem 0;
  font-weight: 500;
`;

const Info = styled.p`
  color: #999;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const News = ({ item }) => {
  return (
    <CardDiv>
      {/* <Card img={ImageTwo}></Card> */}
      <NewsContainer>
        {/* <img src={item.image} alt="news" /> */}
        {item.image_url && (
          <img src={`${STATE_URL}${item.image_url}`} alt="news" />
        )}
        <Link to={"/news/" + item.id}>
          <Title>{item.heading}</Title>
        </Link>
        <Info>{item.news.substring(0, 200)}</Info>
        <Button to={"/news/" + item.id} primary="true">
          Read More
        </Button>
      </NewsContainer>
    </CardDiv>
  );
};

export default News;
