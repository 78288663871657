import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import SideBar from "./components/SideBar";
import GlobalStyle from "./globalStyles";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./pages/Home";
import ScrollToTop from "./ScrollToTop";
import About from "./pages/About";
import ReadNews from "./pages/Readnews";
import News from "./pages/News";
import Capacity from "./pages/Capacity";
import ContactUs from "./pages/ContactUs";
import Membership from "./pages/Membership";
import Application from "./pages/Application";
import Signin from "./pages/Signin";
// import Topbar from "./components/Topbar";

function App() {
  const [showNav, setShowNav] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <>
        <ScrollToTop />
        <GlobalStyle />
        {showNav && <Navbar toggle={toggle} />}
        <SideBar isOpen={isOpen} toggle={toggle} />
        <Routes>
          <Route path="/" element={<Home funcNav={setShowNav} />} />
          <Route path="/about/" element={<About funcNav={setShowNav} />} />
          <Route path="/news/" element={<News funcNav={setShowNav} />} />
          <Route
            path="news/:newsId"
            element={<ReadNews funcNav={setShowNav} />}
          />
          <Route
            path="capacity-building/"
            element={<Capacity funcNav={setShowNav} />}
          />
          <Route
            path="/contact/"
            element={<ContactUs funcNav={setShowNav} />}
          />
          <Route
            path="/register/"
            element={<Membership funcNav={setShowNav} />}
          />
          <Route path="/login/" element={<Signin funcNav={setShowNav} />} />
          <Route
            path="/application/"
            element={<Application funcNav={setShowNav} />}
          />
        </Routes>
        <Footer />
      </>
    </>
  );
}

export default App;
