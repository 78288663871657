import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import { publicRequest, STATE_URL } from "../../requestMethods";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 0rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const Container = styled.div`
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 2rem 5rem;
  line-height: 2rem;
  display: inline-block;

  img {
    float: left;
    width: 500px;
    margin: 2rem;
  }

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    line-height: 3rem;
    color: var(--fourth-color);
  }

  p.dateP {
    margin: 0;
    text-align: flex-start;
    color: var(--text-color-light);
  }

  p {
    margin-bottom: 2rem;
    // display: inline-block;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 1rem auto;

    img {
      margin: 0rem;
    }

    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 0.3rem;
    }
  }
`;

const ReadNews = () => {
  const location = useLocation();
  const newsId = location.pathname.split("/")[2];

  const url = "/news/" + newsId;

  const [tdata, setData] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await publicRequest.get(url);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [url]);

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   publicRequest
  //     .post("/newsComment", { userId, newsId, comment: cmmt })
  //     .then((res) => {
  //       setCmmt("");
  //     });
  // };

  return (
    <InfoDiv>
      <Container>
        <div>
          <h1>{tdata.heading}</h1>
          <p className="dateP">
            {moment(tdata.created_at).format("MMMM DD YYYY | hh:mm a")}
          </p>
        </div>
        {/* <img src={tdata.image} alt="News" /> */}
        {tdata.image_url && (
          <img src={`${STATE_URL}${tdata.image_url}`} alt="news" />
        )}
        <p>{tdata.news}</p>
      </Container>
    </InfoDiv>
  );
};

export default ReadNews;
