import React, { useState } from "react";
import aboutImg from "../images/business-success.webp";
import axios from "axios";
// import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [message, setMessage] = useState("");

  // const navigate = useNavigate();

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   axios
  //     .post("https://admin.iipcelglobal.org/api/member/register", {
  //       email: email,
  //       password: password,
  //       repeat_password: repeatPassword,
  //     })
  //     .then((res) => {
  //       if (res.data.code === 200) {
  //         setMessage(res.data.success);
  //         window.location.href = "https://development.iipcelglobal.org/";
  //       } else {
  //         setMessage(res.data.error);
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.data) {
  //         console.log("Validation Errors: ", err.response.data.errors);
  //         setMessage(err.response.data.message || "An error occurred");
  //       } else {
  //         console.log("Error", err);
  //       }
  //     });
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://admin.iipcelglobal.org/api/member/register", {
        email: email,
        password: password,
        repeat_password: repeatPassword,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setMessage(
            "Registration successful! Check your email for further instructions."
          );
          // Optional: Redirect after a delay
          setTimeout(() => {
            window.location.href = "https://development.iipcelglobal.org/";
          }, 3000); // 3-second delay before redirect
        } else {
          setMessage(res.data.error);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          console.log("Validation Errors: ", err.response.data.errors);
          setMessage(err.response.data.message || "An error occurred");
        } else {
          console.log("Error", err);
        }
      });
  };

  return (
    <div>
      <section
        className="vh-100 bg-image"
        style={{
          backgroundImage: `url(${aboutImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="mask d-flex align-items-center h-100 gradient-custom-3">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                <div className="card" style={{ borderRadius: 15 }}>
                  <div className="card-body p-5">
                    <h2 className="text-uppercase text-center mb-5">
                      Create Account
                    </h2>
                    <p>{message}</p>
                    <form onSubmit={onSubmit}>
                      <div className="form-outline mb-4">
                        <input
                          type="email"
                          id="form3Example3cg"
                          className="form-control form-control-lg"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                        />
                        <label className="form-label" htmlFor="form3Example3cg">
                          Your Email
                        </label>
                      </div>

                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="form3Example4cg"
                          className="form-control form-control-lg"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                        />
                        <label className="form-label" htmlFor="form3Example4cg">
                          Password
                        </label>
                      </div>
                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="form3Example5cg"
                          className="form-control form-control-lg"
                          onChange={(e) => setRepeatPassword(e.target.value)}
                          placeholder="Repeat Password"
                        />
                        <label className="form-label" htmlFor="form3Example5cg">
                          Repeat Password
                        </label>
                      </div>

                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                        >
                          Create
                        </button>
                      </div>
                    </form>
                    <p className="text-center py-3">
                      A member or returning applicant?{" "}
                      <a href="/login"> Log In </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
