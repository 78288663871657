import ImageOne from "../images/business.jpg";

export const SliderData = [
  {
    title:
      "INTERNATIONAL INSTITUTE OF PROFESSIONAL CORPORATE ENTREPRENEURSHIP AND LEADERSHIP",
    price: "#25,000.00",
    path: "/report",
    label: "Submit Report",
    image: ImageOne,
    alt: "Individual Report",
  },
];
