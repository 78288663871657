import React from "react";
import styled from "styled-components";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 80%;
  padding: 0;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const CentralizedText = styled.div`
  text-align: left;
  margin: 0 auto;

  h1 {
    color: #000;
    font-size: clamp(1.5rem, 6vw, 2rem);
    font-weight: 500;
    margin: 0 auto 1rem;
  }

  p {
    margin: 0 auto 2rem;
  }

  @media screen and (max-width: 480px) {
    h1 {
      font-size: 1.3rem;
    }
  }
`;

const InfoSection = () => {
  return (
    <InfoDiv>
      <CentralizedText>
        {/* <h1>
          Creating Global Entreprenures and Leaders, Global Networking throught
          Wealth creation
        </h1> */}
        <h1>The Council of IIPCEL:</h1>
        <p>
          The Council of the International Institute of Professional Corporate
          Entrepreneurship and Leadership, IIPCEL is made up of Professional
          Members of the Institute appointed or elected at Annual General
          Meeting as convened by the Board of Directors. Council Members are
          appointed/elected for two-year tenure subject to renewal. The Council
          is headed by the Chairman who is the Chief Executive Officer of the
          Institute. investment alpha.Marketing pitch gen scrum project.
        </p>
        <p>
          It's Aims and Objectives is to provide Entrepreneurship and Leadership
          training, admit to Membership various persons qualified to become
          Professional members into the various Grades as provided by the
          Council of the Institute.
        </p>
        <p>
          The Professional membership and training is across Nigeria, Africa
          continent and any countries of the world for the international
          advancement of the profession of Entrepreneurship and Leadership.
          IIPCEL is administered through the Council which is the highest
          decision making organ of the Institute
        </p>
      </CentralizedText>
    </InfoDiv>
  );
};

export default InfoSection;
