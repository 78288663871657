import React, { useEffect } from "react";
import PageHero from "../components/PageHero";
import AboutUs from "../components/AboutUs";
import Newsletter from "../components/Newsletter";

const About = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <PageHero name="About Us" />
      <AboutUs />
      <Newsletter />
    </>
  );
};

export default About;
