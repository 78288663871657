import React from "react";
import styled from "styled-components";
import { Button } from "./Button";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 1rem auto;
  background-color: #fff;

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  padding: 1rem calc((100vw - 1300px) / 2);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;
`;

const ColumnLeft = styled.div`
  display: grid;
`;

const ColumnRight = styled.div`
  display: flex;
  align-items: baseline;
`;

const Becoming = () => {
  return (
    <InfoDiv>
      <Container>
        <ColumnLeft>
          <h1>Membership Application Portal</h1>
          <h3>HOW TO APPLY FOR MEMBERSHIP</h3>
          <ol>
            <li>
              Click on “Apply Now”, Make payment of N20,200 application form and
              fill the form. You will be required to attach the following in pdf
              or jpg formats:
            </li>
            <ol>
              <li>Degree/HND Results</li>
              <li>Detailed CV</li>
              <li>NYSC Discharge/Exemption or Exclusion</li>
              <li>Evidence of Change of Name (Married Women Only)</li>
            </ol>
            <li>
              After submitting the application form, you will be notified on the
              status by SMS and email (Please always check your email inbox and
              spam folder, adding our email to your trusted list will ensure our
              mails get to your inbox instead of spam)
            </li>
            <li>
              When your application is approved, you will be required to
              register on the same portal after login (you are adviced to keep
              your password simple so you can always remember it)
            </li>
            <li>
              After registering, you will be able to download the following on
              the portal:
            </li>
            <ol>
              <li>Photocard</li>
              <li>Study Materials</li>
              <li>Examination guidelines</li>
              <li>Examination timetable</li>
            </ol>
            <li>
              IIPCEL RESERVES THE RIGHT TO CHANGE EXAMINATION DATES, VENUE AND
              MODE OF EXAMINATION
            </li>
          </ol>
        </ColumnLeft>
        <ColumnRight>
          <Button to="/application" primary="true">
            Apply Now
          </Button>
        </ColumnRight>
      </Container>
    </InfoDiv>
  );
};

export default Becoming;
