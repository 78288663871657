import React from "react";
import styled from "styled-components";
import roomImg from "../images/oakland.jpg";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 1rem auto;
  background-color: #f8f8f8;

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  padding: 1rem calc((100vw - 1300px) / 2);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  grid-template-rows: 600px;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 1rem 0;
    margin: 0 auto;
    gap: 1rem;
  }
`;

const ColumnLeft = styled.div`
  display: flex;

  img {
    width: 100%;
    height: auto;
    object-fit: fit;
  }
`;

const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  padding: 1rem;

  h1 {
    // color: #252626;
    color: #049abb;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 0;
    width: 100%;
  }
`;

const WhatWeDo = () => {
  return (
    <InfoDiv>
      <Container>
        <ColumnRight>
          {/* <h1>What We Do</h1> */}
          <p>
            IIPCEL facilities ease of doing business in Nigeria and otheer
            countries in Africa and internationally through partnership with
            government policy and regulatory Agencies for the benefit of our
            Members and in diaspora and their business partners as well as other
            investors.
          </p>
          <h1>Our Values</h1>
          <ul>
            <li>Wholesome Entrepreneurship</li>
            <li>Wholeseome Leadership</li>
            <li>Wholesome Corporate Governance and Management</li>
            <li>Innovation</li>
            <li>Creativity</li>
            <li>Pro-activeness</li>
            <li>Value-Focused</li>
            <li>Transparency</li>
            <li>Integrity</li>
            <li>Efficiency & Effectiveness</li>
          </ul>
        </ColumnRight>
        <ColumnLeft>
          <img src={roomImg} alt="Data Stock" />
        </ColumnLeft>
      </Container>
    </InfoDiv>
  );
};

export default WhatWeDo;
