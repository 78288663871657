import React, { useEffect } from "react";
import Footer from "../components/Footer";
import SingleNews from "../components/news/ReadNews";
import PageHero from "../components/PageHero";
import Newsletter from "../components/Newsletter";
import Sponsors from "../components/Sponsors";

const ReadNews = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <PageHero name="News Blog" />
      <SingleNews />
      <Newsletter />
      <Sponsors />
    </>
  );
};

export default ReadNews;
