import React, { useEffect } from "react";
import Hero from "../components/Hero";
import { SliderData } from "../data/SliderData";
import InfoSection from "../components/InfoSection";
import WhatWeDo from "../components/WhatWeDo";
import Sponsors from "../components/Sponsors";
import Latest from "../components/news/Latest";
import Newsletter from "../components/Newsletter";

const Home = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <Hero slides={SliderData} />
      <InfoSection />
      <WhatWeDo />
      <Latest />
      <Newsletter />
      <Sponsors />
    </>
  );
};

export default Home;
