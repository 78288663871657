import React from "react";
import styled from "styled-components";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 80%;
  padding: 2rem;
  margin: 1rem auto;
  background-color: #ffffff;

  img {
    width: 100%;
    height: auto;
    object-fit: fit;
  }

  h1 {
    color: #000;
    font-weight: bold;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const AboutUs = () => {
  return (
    <InfoDiv>
      <h1>About Us</h1>
      <p>
        The International Institute of Professional Corporate Entrepreneurship
        and Leadership, IIPCEL is a Professional Institute incorporated by the
        Corporate Affairs Commission, CAC and limited by Guarantee (RC 1751870).
        It is approved by the Federal Ministry of Education and the Federal
        Ministry of Justice as a Professional Training Institute.
      </p>
      <p>
        The Aims and Objectives is to provide Entrepreneurship and Leadership
        training, admit to Membership various persons qualified to become
        Professional members into the various Grades as provided by the Council
        of the Institute.
      </p>
      <p>
        The Professional membership and training is across Nigeria, Africa
        continent and any countries of the world for the international
        advancement of the profession of Entrepreneurship and Leadership. IIPCEL
        is administered through the Council which is the highest decision making
        organ of the Institute.
      </p>
      <h1>The Council of IIPCEL:</h1>
      <p>
        The Council of the International Institute of Professional Corporate
        Entrepreneurship and Leadership, IIPCEL is made up of Professional
        Members of the Institute appointed or elected at Annual General Meeting
        as convened by the Board of Directors. Council Members are
        appointed/elected for two-year tenure subject to renewal.
      </p>
      <p>
        The Council is headed by the Chairman who is the Chief Executive Officer
        of the Institute.
      </p>
      <p>
        The following are the Members of Council of the International Institute
        of Professional Corporate Entrepreneurship and Leadership, IIPCEL:
      </p>
      <ol>
        <li> Godwin E. Urhie, FPCEL, BDSP - Chairman</li>
        <li>Mrs Tabitha Ajah, FPCEL - Member</li>
        <li>Engr. Mfon E. Essien, FPCEL, FMNSE - Member</li>
        <li>Julius Maduka Ukpabi, FPCEL -Member</li>
        <li>Bitrus Pam Badung, FPCEL -Member</li>
        <li>Mrs Lilian Ene Anyebe, MPCE -Member</li>
        <li>Edwin Lemchi Iwuji, MPCEL -Member</li>
        <li>Oluwatimilehin Tope, MPCEL -Secretary</li>
      </ol>
      <p>
        The Secretary of the Council shall be a non-voting member and a
        Management Committee Member of the Institute, IIPCEL
      </p>
      <h1>The Management Committee of IIPCEL:</h1>
      <p>
        The Management Committee of the Institute shall be responsible for the
        management of the day to day operations and activities of the Institute.
      </p>
      <ul>
        <li>Certified Ethical Hacker (CEH) </li>

        <li>Cisco Certified Academy Instructors (CCAI) </li>

        <li>Cisco Certified Voice Professionals (CCVP) </li>

        <li>Cisco Content Networking Specialists </li>

        <li>Cisco Certified Network Professionals (CCNP) </li>

        <li>Cisco Certified Design Professionals (CCDP) </li>

        <li>Cisco Certified Network Associates (CCNA) </li>

        <li>Cisco Certified Design Associates (CCDA) </li>

        <li>
          Cisco Certified Network Associate Cybersecurity Operations (CCNA
          CyberOps)
        </li>

        <li>Cisco Certified Network Associate Security (CCNA Security) </li>

        <li>Microsoft Certified Systems Engineers (MCSE) </li>

        <li>RedHat Certified Engineers (RHCE) </li>

        <li>Linux Professional Institute (LPI) Certificate </li>

        <li>SUN Solaris Certified Systems Administrators (SCSA) </li>

        <li>MikroTik Certified Network Associate (MTCNA) </li>

        <li>MikroTik Certified Routing Engineer (MTCRE) </li>

        <li>MikroTik Certified Wireless Engineers (MTCWE) </li>

        <li>MikroTik Certified Traffic Control Engineer (MTCTCE) </li>

        <li>MikroTik Certified User Management Engineers (MTCUME) </li>

        <li>MikroTik Certified Inter-networking Engineers (MTCINE) </li>

        <li>Certified VSAT Installers </li>
      </ul>
      <h1>OUR PARTNERS</h1>
      <ul>
        <li>Dapelson Integrated Global Solutions</li>
        <li>Vision Shield Multi-Concept Limited</li>
        <li>Managvis Information Technology</li>
        <li>Business Computing Solution</li>
      </ul>
    </InfoDiv>
  );
};

export default AboutUs;
