import React from "react";
import styled from "styled-components";
import Awep from "../images/awep.jpeg";
import Bizi from "../images/bizi.jpeg";
import Boa from "../images/BOA-Logo.png";
import Nhub from "../images/nhub.png";
import Nysc from "../images/nysc.png";
import one from "../images/1.jpg";
import two from "../images/2.jpg";
import three from "../images/3.jpg";
import four from "../images/4.jpg";
import five from "../images/5.jpg";
import six from "../images/6.jpg";
import seven from "../images/7.jpg";
import eight from "../images/8.jpg";
import nine from "../images/9.jpg";
import ten from "../images/10.jpg";
import eleven from "../images/11.jpg";
import twelve from "../images/12.jpg";
import thirteen from "../images/13.jpg";
import fourteen from "../images/14.jpg";
import fifteen from "../images/15.jpg";
import sixteen from "../images/16.jpg";
import seventeen from "../images/17.jpg";
import eighteen from "../images/18.jpg";
import ninteen from "../images/19.jpg";
import twenty from "../images/20.jpg";
import twentyOne from "../images/21.jpg";

const Container = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
  }
`;

const Inner = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-item: center;
  justify-content: center;
  background-color: #fff;
  gap: 2rem;

  img {
    height: 130px;
    margin: 0 1rem;
  }

  @media screen and (max-width: 1400px) {
    img {
      height: 120px;
      margin: 0 1rem;
    }
  }

  @media screen and (max-width: 1250px) {
    img {
      margin: 0 0.5rem;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 1.5rem;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    img {
      height: 100px;
      margin: 0 1rem;
    }
  }
`;

const Sponsors = () => {
  return (
    <Container>
      <h1>Partners</h1>
      <Inner>
        <img src={Awep} alt="" />
        <img src={Boa} alt="" />
        <img src={Bizi} alt="" />
        <img src={Nhub} alt="" />
        <img src={Nysc} alt="" />
        <img src={one} alt="" />
        <img src={two} alt="" />
        <img src={three} alt="" />
        <img src={four} alt="" />
        <img src={five} alt="" />
        <img src={six} alt="" />
        <img src={seven} alt="" />
        <img src={eight} alt="" />
        <img src={nine} alt="" />
        <img src={ten} alt="" />
        <img src={eleven} alt="" />
        <img src={twelve} alt="" />
        <img src={thirteen} alt="" />
        <img src={fourteen} alt="" />
        <img src={fifteen} alt="" />
        <img src={sixteen} alt="" />
        <img src={seventeen} alt="" />
        <img src={eighteen} alt="" />
        <img src={ninteen} alt="" />
        <img src={twenty} alt="" />
        <img src={twentyOne} alt="" />
      </Inner>
    </Container>
  );
};

export default Sponsors;
