import React from "react";
import styled from "styled-components";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 80%;
  padding: 2rem;
  margin: 1rem auto;
  background-color: #ffffff;

  img {
    width: 100%;
    height: auto;
    object-fit: fit;
  }

  h1 {
    color: #000;
    font-weight: bold;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const CapacityBuilding = () => {
  return (
    <InfoDiv>
      <h1>Capacity Building</h1>
      <h4>
        iipcel-participation-on-the-development-of-entrepreneurs-and-its-ecosystem
      </h4>
      <p>
        The following are the current Capacity Building and Training activities
        on Entrepreneurship being carried out by the Institute and participation
        in the development of Entrepreneurs and the Entrepreneurship ecosystem
        in Plateau State:
      </p>
      <ol>
        <li>
          Partnership/collaboration with the Small and Medium Enterprises
          Development Agency of Nigeria, SMEDAN at Headquarters, Zonal and State
          activities for Entrepreneurs and MSMEs development.
        </li>
        <span>
          These include the MSMEs Support Organizations Dialogue in Ilorin,
          Kwara State; Stakeholders Dialogue on the Implementation of the
          National MSME Policy in Plateau State; and the MSMEs Opportunity Fair
          in Jos, Plateau State.
        </span>
        <li>
          Partnership/Collaboration with Plateau State Microfinance Development
          Agency and participation in joint activities such as (i) above. IIPCEL
          and PLASMIDA are working towards becoming Entrepreneurship and MSMEs
          training partners going forward.
        </li>
        <li>
          Partnership/collaboration with the National Youths Service Corps, NYSC
          Plateau State on Micro Small and Medium Enterprises (MSMEs)
          Skills/Support on Business and Entrepreneurship Training.
        </li>
        <li>
          Partnership/collaboration with Industrial Training Fund, ITF on
          Entrepreneurship and MSMEs Development Training.
        </li>
        <li>
          IIPCEL is in active collaboration with the Agro-Processors
          Association, Plateau State; Technology Incubation Centre,
          Bukuru-Plateau State and National Association of Small Scale
          Industrialists (NASSI), Plateau State on market access through
          creation of marketing network in Nigeria and export.
        </li>
        <li>
          IIPCEL conducts a weekly ONLINE Business and Entrepreneurship Seminar
          for Businessmen and Women in Plateau State and across Nigeria.
        </li>
        <li>
          IIPCEL currently run Business and Entrepreneurship Training Seminars
          with faith-based and socio-cultural organizations on the fundamentals
          of entrepreneurship as well as management support for existing
          businesses and Entrepreneurs.
        </li>
        <span>
          One of such Seminars with Pentecostal Fellowship of Nigeria (PFN)
          comes up on the 25th-26th November, 2022. Similar collaboration are
          ongoing with JNI, AFENABA, ICA and SSIA etc.
        </span>
        <li>
          IIPCEL conducts statutory membership qualification training modules
          for applicants into the Affiliate, Student, Graduate, Associate, Full
          Member and Fellow GRADES of the Institute.
        </li>
        <span>
          Intending Members are required to sit for a qualifying examination for
          admission into the various membership Grades for induction into the
          Institute.
        </span>
        <li>
          The Director-General/Chairman of Council of the Institute, Mr. Godwin
          Urhie is a Business Development Service Provider duly trained at the
          SMEDAN Accredited BDSP Training Centre (Kaduna Business School), a
          Certified BDSP in Plateau State and the Executive Chairman//CEO of
          Oakland Business School, Jos.
        </li>
        <span>
          The Institute therefore, has all the experience, expertise and
          facilities for Entrepreneurs and MSMEs training and support throughout
          their business and entrepreneurship journey with the Institute.
        </span>
        <li>
          IIPCEL is an international Professional Institute with a network and
          capacity to reach the Entrepreneurship, MSMEs and all other business
          community anywhere in the world.
        </li>
        <span>
          IIPCEL is currently a member of the International Council of Small
          Businesses, ICSB with a regular update on all business activities all
          over the world.
        </span>
      </ol>
    </InfoDiv>
  );
};

export default CapacityBuilding;
