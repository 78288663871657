import React, { useEffect } from "react";
import Login from "../components/Login";

const Signin = (props) => {
  useEffect(() => {
    props.funcNav(false);
  }, [props]);
  return (
    <>
      <Login />
    </>
  );
};

export default Signin;
