import React, { useEffect } from "react";
import PageHero from "../components/PageHero";
import CapacityBuilding from "../components/CapacityBuilding";
import Newsletter from "../components/Newsletter";

const Capacity = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <PageHero name="Capacity Building" />
      <CapacityBuilding />
      <Newsletter />
    </>
  );
};

export default Capacity;
