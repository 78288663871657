import React from "react";
import styled from "styled-components";

const Container = styled.div`
  color: #fff;
  background-color: #f8f8f8;
  padding: 5rem 0;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 960px) {
    padding: 3rem 0;
  }
`;

// const Container = styled.div`
//   overflow: auto;
//   position: relative;

// :before {
//     content: "";
//     left: 0;
//     right: 0;
//     top:-175px;
//     z-index: -1;

//     display: block;
//     background-image: url(${Img});
//     background-size:cover;
//     background-position:center top;

//     width: 100%;
//     height: 300px;
// }
// `;

const Content = styled.div`
  text-align: left;
  justify-content: center;
  align-items: center;
  color: #000;

  h1 {
    color: #f4cf3f;
    padding: 0.5rem 2rem;
    text-style: underline;
  }

  span {
    font-weight: 500;
    letter-spacing: 0.5em;
  }

  @media screen and (max-width: 960px) {
    h1 {
      margin-top: 1rem;
      padding: 1rem 0;
    }
  }
`;

const PageHero = (props) => {
  return (
    <div>
      <Container>
        <Content>
          <h1>{props.name}</h1>
          {/* <span>Home ...{props.name}</span> */}
        </Content>
      </Container>
    </div>
  );
};

export default PageHero;
