import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { items } from "../data/RoomData";
import News from "./News";
import { publicRequest } from "../../requestMethods";

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
  margin: 2rem auto;
  justify-content: center;
  background: rgba(83, 83, 83, 0.05);
`;

const HeroTitle = styled.h1`
  color: #000;
  text-align: center;
  margin-top: 20px;
`;

const NewsPage = () => {
  const url = "/news";

  const [tdata, setData] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await publicRequest.get(url);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, []);

  return (
    <>
      <HeroTitle>News & Events</HeroTitle>
      <Container>
        {tdata
          //   .filter((item) => item.fields.featured === true)
          .map((item) => (
            <News item={item} key={item.id} />
          ))}
      </Container>
    </>
  );
};

export default NewsPage;
