import axios from "axios";

// const BASE_URL = "http://localhost:8081/api/";
// export const INDEX_URL = "http://localhost:8081/api";
// export const STATE_URL = "http://localhost:8081";

const BASE_URL = "https://admin.iipcelglobal.org/api/";
export const INDEX_URL = "https://admin.iipcelglobal.org/api";
export const STATE_URL = "https://admin.iipcelglobal.org";

// const TOKEN =
//   JSON.parse(localStorage.getItem("persist:root")) &&
//   JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
//     .currentUser !== null &&
//   JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).currentUser
//     .token;

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

// export const userRequest = axios.create({
//   baseURL: BASE_URL,
//   headers: { authorization: `Bearer ${TOKEN}` },
// });
