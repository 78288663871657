import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled(Link)`
  background: ${({ primary }) => (primary ? "#d2ae62" : "#e64100")};
  white-space: nowrap;
  outline: none;
  border: none;
  border-radius: 3px;
  min-width: 100px;
  max-width: 200px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-item: center;
  padding: ${({ big }) => (big ? "14px 35px" : "12px 20px")};
  color: ${({ primary }) => (primary ? "#fff" : "#000d1a")};
  font-size: ${({ big }) => (big ? "20px" : "14px")};

  &:hover {
    transform: translateY(-2px);
    background: ${({ primary }) => (primary ? "#e64100" : "#FF893B")};
  }
`;
