import React, { useEffect } from "react";
import PageHero from "../components/PageHero";
import Contact from "../components/Contact";

const ContactUs = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <PageHero name="Contact Us" />
      <Contact />
    </>
  );
};

export default ContactUs;
