import React, { useEffect } from "react";
import NewsPage from "../components/news/NewsPage";
import Sponsors from "../components/Sponsors";
import PageHero from "../components/PageHero";
import Newsletter from "../components/Newsletter";

const News = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <PageHero name="News" />
      <NewsPage />
      <Newsletter />
      <Sponsors />
    </>
  );
};

export default News;
